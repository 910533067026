<template>
  <div style="height: 100%; background-color: #ffffff">
    <head-layout :head-title="headTitle" :head-btn-options="headBtnOptions" @head-save="headSave(false, 'PREPARE')"
      @head-finished="headSave(true, 'FINISHED')" @head-saveBack="headSave(true, 'CONFIRMED')"
      @head-cancel="headCancel"></head-layout>
    <div class="formContentBox">
      <div class="formMain" style="padding: 0 12px">
        <el-form ref="dataForm" :model="dataForm" label-width="150px"
          :disabled="formType == 'view' || dataForm.actStatus == 'CONFIRMED'" :rules="rules">
          <!-- <div class="formTopic" style="margin-bottom: 12px">检查基本信息</div> -->
          <el-row>
            <el-col :span="8">
              <el-form-item label="检查编号" prop="actCode">
                <el-input v-model="dataForm.actCode" :disabled="dataForm.isAutomatic || pageDisabled || dataForm.id
                  " placeholder="请输入检查编号">
                  <template slot="append">
                    自动生成
                    <el-switch :disabled="pageDisabled || dataForm.id" v-model="dataForm.isAutomatic"
                      active-color="#13ce66" @change="handleSwitch">
                    </el-switch>
                  </template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="检查名称" prop="actName">
                <el-input v-model="dataForm.actName" placeholder="请输入检查名称" :title="dataForm.actName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="检查类型" prop="checkType">
                <el-select v-model="dataForm.checkType" placeholder="请选择检查类型" disabled>
                  <el-option v-for="item in safey_check_type" :key="item.dictKey" :label="item.dictValue"
                    :value="item.dictKey">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="录入人" prop="startUserName">
                <el-input v-model="dataForm.startUserName" @focus="headAdd('startUserName')"
                  placeholder="请选择录入人"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="所属项目" prop="organizationName">
                <el-input v-model="dataForm.organizationName"   @focus="handleProjectOPen()"
                  :title="dataForm.organizationName" placeholder="请填写所属项目"></el-input>
              </el-form-item>
            </el-col>
             <el-col :span="8">
              <el-form-item label="检查日期" prop="checkTime">
                <el-date-picker type="datetime" value-format="yyyy-MM-dd HH:mm:ss" v-model="dataForm.checkTime"
                  placeholder="请选择检查日期"></el-date-picker>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="8">
              <el-form-item label="检查组组长" prop="checkLeaderName">
                <el-input v-model="dataForm.checkLeaderName" @focus="headAdd('checkLeaderName')"
                  placeholder="请选择检查组组长"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="参与人" prop="checkJoinName">
                <el-input v-model="dataForm.checkJoinName" @focus="headAdd1('checkJoinName')"
                  placeholder="请选择参与人"></el-input>
              </el-form-item>
            </el-col> -->
           
          </el-row>

          <el-row>
            <el-col :span="24">
              <el-form-item label="检查记录" prop="remark">
                <el-input v-model="dataForm.remark" type="textarea" :autosize="{ minRows: 4 }" show-word-limit
                  maxlength="500" placeholder="请填写检查记录"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="shuttleBackBox">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="检查组组长" name="thirdPartyOrganization"></el-tab-pane>
            <el-tab-pane label="参与人员" name="checkJoinTab"></el-tab-pane>
            <el-tab-pane label="检查项" name="corePersonnel"></el-tab-pane>
            <el-tab-pane label="相关资料 " name="thirdPartyPersonnel"></el-tab-pane>
            <el-tab-pane label="隐患清单" v-if="formType == 'view'" name="taskList"></el-tab-pane>
            <el-tab-pane label="整改通知书" v-if="formType == 'view'" name="Rectify"></el-tab-pane>
            <el-tab-pane label="处罚通知书" v-if="formType == 'view'" name="punishment"></el-tab-pane>
          </el-tabs>
          <el-container class="main-box" v-if="activeName == 'corePersonnel'">
            <CommonTree treeTitle="检查项分类" :isShowdig="dataForm.actStatus == 'PREPARE'&&formType != 'view'" node-key="id" isLeafIcons
              :defaultExpandedKeys="defaultExpandedKeys" iconsFlied="isObject" ref="commonTreeCore"
              @getTreeAdd="getTreeAdd" @getTreeDelete="getTreeDelete" @getTreeEdit="getTreeEdit" searchTitle="typeName"
              :treeData="corePersonnelTreeData" :defaultProps="defaultProps" @getNodeClick="corePersonnelTreeNodeClick"
              :showCheckbox="false" :treeExpand="true" />
            <div class="table-box">
              <head-layout v-if="formType != 'view'" :head-btn-options="dataForm.actStatus == 'PREPARE'&&dataForm.id
                ? corePersonnelHeadBtnOptions
                : []
                " @head-export="headExport" @head-fx="headFx" @head-del="headDel" @handleImport="handleImport"
                @head-add="addjcx" head-title="检查项"></head-layout>
              <grid-layout ref="jcxGridLayOut" v-if="dataForm.actStatus" :table-options="corePersonnelTableOption"
                :data-total="page.total" :page="page" :cell-style="cellStyle" @page-current-change="getJcx"
                @page-size-change="getJcx" :table-data="corePersonnelTableData" :table-loading="jcxTableLoading"
                @gird-handle-select-click="selectionChange">
                <template #customBtn="{ row }">
                  <el-button v-if="
                    formType == 'view' && dataForm.actStatus == 'FINISHED'
                  " style="margin: 0 3px" type="text" size="small" @click="zxjc(row)">查看
                  </el-button>
                  <el-button v-if="formType != 'view' && dataForm.actStatus == 'PREPARE'" style="margin: 0 3px"
                    type="text" size="small" @click="addjcx(row)">编辑
                  </el-button>
                  <el-button v-if="formType != 'view' && dataForm.actStatus == 'PREPARE'" style="margin: 0 3px"
                    type="text" size="small" @click="rowDel([row])">删除
                  </el-button>
                </template>
              </grid-layout>
            </div>
          </el-container>
          <div v-if="activeName == 'thirdPartyOrganization'" >
            <head-layout v-if="formType != 'view'" head-title="检查组组长" :head-btn-options="dataForm.actStatus == 'PREPARE' ? userBtnOptions : []
              " @head-add="headAdd('jcry')" @head-remove="headRemove"></head-layout>
            <grid-layout :key="thirdPartyOrganization" ref="gridLayOut1" :table-options="thirdPartyOrganizationTableOption"
              :data-total="jcryPage.total" :page="jcryPage" @page-current-change="getJcUser"
              @page-size-change="getJcUser" :table-data="thirdPartyOrganizationTableData"
              :table-loading="jcryTableLoading" @gird-handle-select-click="selectionUserChange">
              <template #customBtn="{ row }" v-if="formType != 'view' && dataForm.actStatus == 'PREPARE'">
                <el-button style="margin: 0 3px" type="text" size="small" @click="rowUserDel([row],'jcry')">删除</el-button>
              </template>
            </grid-layout>
          </div>
          <div v-if="activeName == 'checkJoinTab'" >
            <head-layout v-if="formType != 'view'" head-title="参与人员" :head-btn-options="dataForm.actStatus == 'PREPARE' ? cyryBtnOptions : []
              " @head-add="headAdd1('cyry')" @head-remove="headRemove"></head-layout>
            <grid-layout  ref="gridLayOut2" :table-options="checkJoinDataOption"
              :data-total="cyryPage.total" :page="cyryPage" @page-current-change="getJcUser"
              @page-size-change="getJcUser" :table-data="checkJoinData"
              :table-loading="checkJoinDataLoading" @gird-handle-select-click="selectionUserChange">
              <template #customBtn="{ row }" v-if="formType != 'view' && dataForm.actStatus == 'PREPARE'">
                <el-button style="margin: 0 3px" type="text" size="small" @click="rowUserDel([row],'cyry')">删除</el-button>
              </template>
            </grid-layout>
          </div>
          <div v-if="activeName == 'thirdPartyPersonnel'">
            <head-layout v-if="formType != 'view'" head-title="资料清单" :head-btn-options="dataForm.actStatus == 'PREPARE' ? fileBtnOptions : []
              " @headImport="headImport">
              <el-button @click="quote()" size="mini" slot="right">知识库选择</el-button>
            </head-layout>
            <el-upload :disabled="dataForm.actStatus != 'PREPARE' ? true : false" :before-remove="beforeRemove"
              :on-remove="handleRemove" :on-preview="handlePreview" :file-list="xgzlFileList" class="upload-demo">
            </el-upload>
          </div>
          <div v-if="activeName == 'taskList'">
            <grid-layout ref="gridLayOut2" :table-options="hdchecktaskproOption" @grid-row-detail-click="rowView"
              :table-data="hdchecktaskproData" :table-loading="tableLoading"
              @gird-handle-select-click="selectionChange">
              <template #customBtn="{ row }" v-if="formType == 'view'">
                <el-button style="margin: 0 3px" type="text" size="small" @click="rowView(row)">查看</el-button>
              </template>
            </grid-layout>
          </div>
          <el-upload v-if="activeName == 'Rectify'" disabled :on-preview="handlePreview1" :file-list="RectifyList"
            class="upload-demo">
          </el-upload>
          <el-upload v-if="activeName == 'punishment'" disabled :on-preview="handlePreview1" :file-list="punishmentList"
            class="upload-demo">
          </el-upload>
        </div>
      </div>
    </div>
    <el-upload v-show="hideUpload" accept=".doc,.docx,.pdf,.xlsx,.xls,.zip,.jpg,.png,.rar,.ppt,.pptx"
      class="upload-demo" action="/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/km" :show-file-list="false"
      :on-success="handleSuccess" ref="uploadRef" :headers="headers"></el-upload>
    <el-dialog title="人员选择" :visible.sync="deptShow" v-if="deptShow" width="80%" top="8vh">
      <!-- <UserDetpDialog ref="UserDetpDialog" :dept-category="[2, 5]" @select-data="selectData"></UserDetpDialog> -->
      <ProjectSelectUser @closeDia="deptShow=false" :treeParams="{parentId: dataForm.organizationId}" v-if="deptShow" @select-data="getUser"></ProjectSelectUser>
    </el-dialog>
    <el-dialog title="人员选择" :visible.sync="MultipShow" width="80%" top="8vh" v-if="MultipShow">
      <!-- <UserDeptMultipleDialog @closeDia="MultipShow = false" ref="UserDeptMultipleDialog" @select-all-data="selectData">
      </UserDeptMultipleDialog> -->
      <ProjectSelectUser @closeDia="MultipShow=false" v-if="MultipShow" :treeParams="{parentId: dataForm.organizationId}" @select-data="getUser" multiple></ProjectSelectUser>
    </el-dialog>
    <el-dialog title="项目选择" :visible.sync="deptShow1" width="80%">
      <DeptDialog ref="DeptDialog" :deptCategory="[5]" @select-data="selectData1" :isOnlyShowPrj="true"></DeptDialog>
    </el-dialog>
    <el-dialog title="检查分类导入" v-if="excelBox" append-to-body :visible.sync="excelBox" width="555px">
      <div class="import">
        <avue-form :option="excelOption" v-model="excelOption.excelForm" :upload-after="uploadAfter">
          <template slot="excelTemplate">
            <el-button type="primary" @click="handleTemplate">
              {{ $t("cip.plat.sys.user.field.download")
              }}<i class="el-icon-download el-icon--right"></i>
            </el-button>
          </template>
        </avue-form>
      </div>
    </el-dialog>
    <el-dialog title="检查项" v-dialogDrag v-if="jcxModel" :visible.sync="jcxModel" width="40%">
      <avue-form :option="jcxOption" v-if="jcxModel" @reset-change="resetChange1" @submit="handleSubmit1"
        v-model="jcxForm"></avue-form>
    </el-dialog>
    <el-dialog title="检查对象及分类新增" v-if="jcxflModel" v-dialogDrag :visible.sync="jcxflModel" width="50%">
      <avue-form :option="jcxflOption" @reset-change="resetChange" @submit="handleSubmit" v-model="jcxflForm">
        <template #typeName>
          <el-input v-model="jcxflForm.typeName" v-if="
            jcxflForm.objectType == 'SD' ||
            jcxflForm.id ||
            jcxflForm.isObject == 0
          "></el-input>
          <el-button @click="handleTable('EQ')" type="primary" v-if="
            jcxflForm.objectType == 'EQ' &&
            !jcxflForm.id &&
            jcxflForm.isObject == 1
          ">选择设备设施</el-button>
          <el-button type="primary" v-if="
            jcxflForm.objectType == 'AREA' &&
            !jcxflForm.id &&
            jcxflForm.isObject == 1
          " @click="handleTable('qy')">选择区域</el-button>
          <el-button type="primary" v-if="
            jcxflForm.objectType == 'WORK' &&
            !jcxflForm.id &&
            jcxflForm.isObject == 1
          " @click="handleTable('ts')">选择特殊作业</el-button>
        </template>
      </avue-form>
    </el-dialog>
    <el-dialog title="风险库及措施" v-if="fxyModel" v-dialogDrag :visible.sync="fxyModel" width="80%" class="dangerBox">
      <el-container style="height: 500px">
        <CommonTree ref="CommonTree" treeTitle="风险源" :defaultProps="{
          children: 'children',
          id: 'id',
          label: 'sourceTypeName',
        }" searchTitle="sourceTypeName" :showCheckbox="false" :treeData="treeData" :treeExpand="true"
          style="font-size: 13px" @getNodeClick="handleNodeClick" />
        <el-main>
          <!--                    <grid-head-layout-->
          <!--                      :searchSpan="2"-->
          <!--                      ref="searchForm"-->
          <!--                      :search-columns="searchColumns"-->
          <!--                      @grid-head-search="searchChange"-->
          <!--                      @grid-head-empty="searchReset"-->
          <!--                    />-->
          <div class="searchTopBox">
            <div class="leftSearch">
              <div style="width: 100%; display: flex; margin-bottom: 6px">
                <el-input v-model="searchForm.riskName" size="mini" placeholder="请输入风险名称" class="searchBox"></el-input>
                <el-input v-model="searchForm.treatmentTypeName" size="mini" placeholder="请输入措施分类"
                  class="searchBox"></el-input>
                <el-input v-model="searchForm.treatmentDesc" size="mini" placeholder="请输入措施描述"
                  class="searchBox"></el-input>
                <el-input v-model="searchForm.hdCheckContext" size="mini" placeholder="请输入隐患排查内容"
                  class="searchBox"></el-input>
                <el-select clearable v-model="searchForm.controlLevel" placeholder="请输入管控层级" size="mini"
                  class="searchBox">
                  <el-option v-for="item in riskControlLevel" :key="item.dictKey" :label="item.dictValue"
                    :value="item.dictKey">
                  </el-option>
                </el-select>
                <el-input v-model="searchForm.respUserName" size="mini" placeholder="请输入负责人员"
                  class="searchBox"></el-input>
                <div class="rightSearch">
                  <el-button-group>
                    <el-button size="mini" type="primary" icon="el-icon-search" @click="searchChange"></el-button>
                    <el-button size="mini" icon="reset-refresh icon-refresh" @click="searchReset"
                      style="height: 28px"></el-button>
                  </el-button-group>
                </div>
              </div>
            </div>
          </div>
          <grid-layout ref="gridLayOutfxy" :table-options="fxyOption" :data-total="tableTotal"
            :table-data="fxyTableData" :table-loading="fxyTableLoading" :page="fxyPage"
            @gird-handle-select-click="selectionChange" @page-size-change="handleSizeChange"
            @page-current-change="handleCurrentChange">
          </grid-layout>
        </el-main>
      </el-container>
      <div class="avue-dialog__footer">
        <el-button @click="fxyModel = false">取 消</el-button>
        <el-button @click="handleFxySave" type="primary">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog v-dialog-drag title="附件预览" :modal="true" :modal-append-to-body="false" :close-on-click-modal="false"
      :visible.sync="showFileDialog" width="70%">
      <iframe ref="fileIframe" :src="fileUrl" frameborder="0" style="width: 100%; height: 500px"></iframe>
      <span slot="footer">
        <el-button size="small" @click="showFileDialog = false">取消</el-button>
      </span>
    </el-dialog>
    <fileView ref="fileView" title="附件预览"></fileView>
    <el-dialog title="检查项查看" v-dialogDrag v-if="zxhcModel" :visible.sync="zxhcModel"
      class="avue-dialog avue-dialog--top" width="60%">
      <el-form ref="modelFormDataForm" :model="modelForm" label-width="150px" :disabled="modelForm.disabled">
        <el-row>
          <el-col :span="12">
            <el-form-item label="检查结果" prop="lineStatus">
              <el-radio v-model="modelForm.lineStatus" label="NORMAL">正常</el-radio>
              <el-radio v-model="modelForm.lineStatus" label="ERROR">异常</el-radio>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="检查地点" prop="prjName">
              <el-input v-model="modelForm.position" placeholder="请填写检查地点">
                <template slot="append">
                  <div class="el-icon-map-location" style="width: 54px; text-align: center"></div>
                </template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="检查内容" prop="indexName">
              <el-input v-model="modelForm.indexName" type="textarea" :autosize="{ minRows: 2 }" maxlength="500"
                show-word-limit placeholder="请输入检查内容"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="隐患编号" prop="hdCode">
              <el-input v-model="modelForm.hdCode" disabled placeholder="隐患编号自动生成"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="隐患描述" prop="hdDesc">
              <el-input type="textarea" :autosize="{ minRows: 2 }" show-word-limit v-model="modelForm.hdDesc"
                maxlength="225" placeholder="请填写隐患描述"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="隐患情况" prop="prjAs">
              <div class="content">
                <div v-for="(item, index) in ModelTreeData" :key="item.id" class="img-content"
                  @click="handlePreview(item)">
                  <img v-if="
                    item.extension == 'jpg' ||
                    item.extension == 'png' ||
                    item.extension == 'image/jpeg' ||
                    item.extension == 'image/png'
                  " :src="item.link" alt="" />
                  <img v-else src="@/assets/images/dashboard-nodata.png" alt="" />
                </div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="avue-dialog__footer">
        <el-button @click="zxhcModel = false">取 消</el-button>
      </div>
    </el-dialog>
    <selectProjectDialogVue ref="selectProjectDialog" @changeProject="changeProject"></selectProjectDialogVue>
    <WorkTicket ref="WorkTicket" :prjId="dataForm.organizationId"  v-if="WorkTicketShow" @selectWorkTicket="selectWorkTicket"></WorkTicket>
    <riskArea ref="riskArea" multiple @callback="selectWorkTicket"></riskArea>
    <equipmentDialog ref="equipmentDialog" @callback="selectWorkTicket" />
    <el-dialog top="9vh" title="知识库选择" v-if="showKnowledgeSearchDialog" :visible.sync="showKnowledgeSearchDialog"
      width="80%">
      <KnowledgeSearchDialog ref="knowledgeSearchDialog" @close-dialog="closeKnowledgeSearchDialog"
        @select-data="selectFile"></KnowledgeSearchDialog>
    </el-dialog>
  </div>
</template>
<script>
import CommonTree from "@/views/components/com_tree/index";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import ProjectSelectUser from "@/views/components/UserDeptDialog/projectSelectUser";
import selectProjectDialogVue from '@/views/business/safe/blacklistOrganization/components/selectProjectDialog.vue';
import mixins from "./mixins";

import UserDetpDialog from "@/views/components/UserDeptDialog/UserDetpDialog.vue";
import { getToken } from "@/util/auth";
import { exportBlob } from "@/api/common";
import { downloadXls } from "@/util/util";
import { dateNow } from "@/util/date";
import { dictionaryBiz } from "@/api/reportTasks";
import { mapGetters } from "vuex";
import DeptDialog from "@/views/components/UserDeptDialog/DeptDialog.vue";
import * as API from "@/api/check/daily";
import {
  hdchecktasklineDetail,
  hdchecktasklineList,
} from "@/api/check/checkTasks";
import * as project from "@/api/riskManage/project";
import { hdchecktaskproPage } from "@/api/check/daily";
import website from "@/config/website";
import UserDeptMultipleDialog from "@/views/components/UserDeptDialog/UserDeptMultipleDialog.vue";
import WorkTicket from "@/components/WorkTicket/index.vue";
import riskArea from "@/views/hiddenTrouble/check/daily/region.vue";
import snows from "@/util/snows";
import Template from "@/views/message/template/list.vue";
import KnowledgeSearchDialog from "@/views/components/knowledge/KnowledgeSearchDialog.vue";
import equipmentDialog from "@/views/equipmentFacilities/equipmentMaintenance/compoment/equipmentDialog.vue";

export default {
  mixins: [mixins],
  components: {
    equipmentDialog,
    Template,
    DeptDialog,
    UserDetpDialog,
    UserDeptMultipleDialog,
    WorkTicket,
    riskArea,
    CommonTree,
    GridLayout,
    KnowledgeSearchDialog,
    HeadLayout,
    ProjectSelectUser,
    selectProjectDialogVue
  },
  data () {
    return {
      dataForm: { id: '' },
      respUserList: [],
      defaultExpandedKeys: [],
      dialogType: "",
      showKnowledgeSearchDialog: false,
      searchForm: {},
      fileUrl: "",
      modelForm: {},
      zxhcModel: false,
      modelType: false,
      WorkTicketShow: false,
      MultipShow: false,
      showFileDialog: false,
      tableTotal: 0,
      isSource: false,
      fxyTableLoading: false,
      hdchecktaskproData: [],
      ModelTreeData: [],
      treeData: [],
      fxyTableData: [],
      nowTreeNode: {},
      corePersonnelTreeData: [],
      jcxForm: {
        indexTypeId: "",
        indexTypeName: "",
        respUserName: "",
        indexName: "",
      },
      fxyPage: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      jcxflForm: {
        parentId: -1,
        objectName: "",
        typeName: "",
        isObject: 0,
      },
      selectTree: undefined,
      safey_check_type: [],
      riskControlLevel: [],
      punishmentList: [],
      RectifyList: [],
      xgzlFileList: [],
      userList: [],
      fxyModel: false,
      deptShow: false,
      jcxModel: false,
      jcxflModel: false,
      deptShow1: false,
      type: "",
      hideUpload: false,
      excelBox: false,
      lineStatusColor: {
        PREPARE: "rgb(56, 148, 255)",
        NORMAL: "rgb(122, 199, 86)",
        ERROR: "rgb(242, 97, 97)",
      },
      equipmentInfo: {}, // 设备检查-设备信息 
      workPermitType: [],
      checkePersonObj: {}
    };
  },
  computed: {
    pageDisabled () {
      // 如果查看 或者 提交状态则禁用
      if (this.formType == "view" || this.dataForm.actStatus == "PREPARE") {
        return true;
      } else {
        return false;
      }
    },
    headTitle () {
      if (this.safey_check_type.length == 0) return "";
      if (!this.dataForm.checkType) return "";
      let { dictValue } = this.safey_check_type.filter(
        (item) => item.dictKey == this.dataForm.checkType
      )[0];
      return dictValue;
    },
    headers: function () {
      return { "Sinoma-Auth": getToken() };
    },
    ...mapGetters(["userInfo"]),
    ids () {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    userIds () {
      let ids = [];
      this.userList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
  },
  async mounted () {
    let doned = this.$route.query.doned || 0;
    let type = doned == 1 ? 'view' : this.$route.query.type;
    this.formType = type;
    if (this.$route.query.equipmentType) {
      this.equipmentInfo = {
        eqTypeId: this.$route.query.equipmentType,
        typeCode: this.$route.query.equipmentCode,
        typeName: this.$route.query.equipmentName,
      }
      this.dataForm.actName = `${this.equipmentInfo.typeCode}${this.equipmentInfo.typeName}专项检查`
    }
    if (type == "edit") {
      this.dataForm.id = this.$route.query.id;
      this.getPrjbasicinfoDetail();
    } else if (type == "view") {
      this.dataForm.id = this.$route.query.id;
      this.getPrjbasicinfoDetail();
    } else {
      // this.$set(this.dataForm, "organizationId", this.userInfo.dept_id);
      // this.$set(this.dataForm, "organizationName", this.userInfo.deptName);
      this.$set(this.dataForm, "organizationId", this.$route.query.organizationId);
      this.$set(this.dataForm, "organizationName", this.$route.query.organizationName);
      this.$set(this.dataForm, "startUserId", this.userInfo.user_id);
      this.$set(this.dataForm, "startUserName", this.userInfo.real_name);

      this.$set(this.dataForm, "actStatus", "PREPARE");
      this.getPageCode();
      this.$set(
        this.dataForm,
        "checkType",
        String(this.$route.query.checkType)
      );
    }
    // check_type->safey_check_type
    dictionaryBiz("safey_check_type").then((res) => {
      this.safey_check_type = res.data.data.filter(
        (item) => item.dictKey != "7"
      );
    });
    dictionaryBiz("risk_control_level").then((res) => {
      this.riskControlLevel = res.data.data;
    });
    dictionaryBiz("work_permit_type").then((res) => {
      this.workPermitType = res.data.data;
    });
  },
  methods: {
    closeKnowledgeSearchDialog () {
      this.showKnowledgeSearchDialog = false;
    },
    handleTable (type) {
      this.modelType = type;
      if (type == "ts") {

        this.WorkTicketShow = true;
        this.$nextTick(() => {
          if(this.$refs.WorkTicke){
            this.$refs.WorkTicket.closeDialog();
          }
        });
      } else if (type == "qy") {
        this.$refs.riskArea.init(this.dataForm.organizationId);
      } else if (type == "EQ") {
        this.$refs.equipmentDialog.init({orgId:this.dataForm.organizationId});
      }
    },

    selectWorkTicket (data) {
      let newData = !Array.isArray(data) ? [data] : data;
      let saveList = newData.map((item) => {
        let objectName = "";
        if (item.ticketType) {
          let ticketName = this.workPermitType.find(i=>i.dictKey==item.ticketType).dictValue
          objectName = `${ticketName}-${item.content.substring(0, 10)}`;
        } else {
          objectName = item.name || item.areaName || item.equipmentName;
        }
        return {
          // ...item,
          targetId: this.modelType == "EQ" ? item.id : "",
         // objectCode: this.modelType == "EQ" ? item.equipmentCode : "",
          objectType: this.jcxflForm.objectType,
          typeName: objectName,
          parentId: this.jcxflForm.parentId || -1,
          typeId: this.jcxflForm.parentId || -1,
          actId: this.dataForm.id,
          // stTypeId: this.modelType == "EQ" ? item.standardTypeId : "",
          stTypeId: this.modelType == "EQ" ? item.typeId : "", // 20240925：设备类型废弃、设备分类与标准库对齐
          typeCode: this.modelType == "qy" ? item.areaCode : this.modelType == "EQ" ? item.equipmentCode: "",
        };
      });
      API.hdcheckindexSaveList(saveList).then((res) => {
        this.jcxflModel = false;
        this.$message.success(res.data.msg);
        this.typeTree();
      });
    },
    showFile (item) {
      this.fileUrl =
        process.env.VUE_APP_BASE_KKFILEURL +
        "?url=" +
        encodeURIComponent(Base64.encode(item.link));
      this.showFileDialog = true;
      //exls表格预览隐藏打印按钮
      if (process.env.NODE_ENV === "production") {
        const iframe = this.$refs.fileIframe;
        const iframeDocument =
          iframe.contentDocument || iframe.contentWindow.document;

        // 在 iframe 内部隐藏元素
        if (iframeDocument) {
          const elementToHide = iframeDocument.getElementById("button-area");
          if (elementToHide) {
            elementToHide.style.display = "none";
          }
        }
      }
    },
    async zxjc (row) {
      let lineStatus = row.lineStatus == "PREPARE" ? "" : row.lineStatus;
      await hdchecktasklineDetail({ id: row.id }).then((res) => {
        let data = res.data.data;
        this.modelForm = {
          ...data,
          yhID: data.hdHiddenDanger.id,
          ...data.hdHiddenDanger,
          disabled: true,
          lineStatus: lineStatus || "NORMAL",
        };
        this.ModelTreeData = data.hdHiddenDanger.hdUrl
          ? JSON.parse(data.hdHiddenDanger.hdUrl)
          : [];
        this.zxhcModel = true;
      });
    },
    cellStyle ({ row, column, rowIndex, columnIndex }) {
      if (column.property == "lineStatus") {
        return {
          color: this.lineStatusColor[row.lineStatus],
        };
      }
    },
    async getPageCode () {
      let code = await API.getCode().then((res) => res.data.data);
      this.$set(this.dataForm, "actCode", code);
    },
    handleSwitch () {
      if (this.dataForm.isAutomatic) {
        this.getPageCode();
      } else {
        this.dataForm.actCode = "";
      }
    },
    quote () {
      this.showKnowledgeSearchDialog = true;
      // if (this.dataForm.id) {
      //   this.showKnowledgeSearchDialog = true;
      // } else {
      //   this.$message({
      //     message: "基础信息未保存,请先保存基础信息",
      //     type: "warning",
      //   });
      // }
    },
    rowView (row) {
      this.$router.push({
        path: `/hiddenTrouble/riskitemView/checklist/edit`,
        query: {
          id: row.id,
          type: "view",
        },
      });
    },
    handleFxySave () {
      let selectionList = this.selectionList.map((item) => {
        return {
          ...item,
          id: "",
          indexName: item.hdCheckContext,
          objectId: this.selectTree.id,
          objectName: this.selectTree.typeName,
          actId: this.dataForm.id,
        };
      });
      API.hdcheckindexsaveList(selectionList).then((res) => {
        this.fxyModel = false;
        this.selectionList = [];
        this.$message.success(res.data.msg);
        this.getJcx();
      });
    },
    headFx () {
      if (!this.selectTree) return this.$message.success("请选择检查项对象");
      this.fxyModel = true;
      this.searchReset();
      this.getdemotree();
    },
    getNodeId (clo, id) {
      // console.log(clo, id, 9999);
      if (id) {
        const result = this.treeArrData.find((item) => item.id == id);
        // console.log(result, this.treeArrData, id, "getNodeId0000");
        this.isSource = result.isSource;
        this.nowTreeNode = result || {};
      } else if (clo && clo.value) {
        const result = this.treeArrData.find((item) => item.id == clo.value);
        this.isSource = result.isSource || false;
        this.nowTreeNode = result || {};
      }
    },
    searchReset () {
      this.searchForm = {};
      this.fxyPage.currentPage = 1;
      this.searchChange();
    },
    searchChange () {
      let params = {
        ...this.searchForm,
        size: this.fxyPage.pageSize,
        current: this.fxyPage.currentPage,
        organizationId: this.dataForm.organizationId,
      };
      if (this.isSource) {
        params.sourceId = this.nowNodeId;
      } else {
        params.sourceTypeId = this.nowNodeId;
      }
      // 调用列表接口;
      this.fxyTableLoading = true;
      API.pmriskTreatment(params)
        .then((res) => {
          // console.log(res);
          this.fxyTableData = res.data.data.records;
          this.tableTotal = res.data.data.total;
          this.fxyTableLoading = false;
        })
        .catch((error) => {
          this.fxyTableLoading = false;
        });
    },
    handleSizeChange (e) {
      this.fxyPage.pageSize = e.pageSize;
      this.searchChange();
    },
    handleCurrentChange (e) {
      this.fxyPage.currentPage = e.currentPage;
      this.searchChange();
    },
    //点击树节点显示列表
    handleNodeClick (data) {
      this.nowNodeId = data.id;
      this.getNodeId({}, this.nowNodeId);
      this.fxyPage.currentPage = 1;
      this.searchChange();
    },
    //扁平化 树
    flattenTree (tree, parent = null) {
      let result = [];
      for (let node of tree) {
        let flatNode = { ...node, parent };
        result.push(flatNode);
        if (node.children && node.children.length) {
          result = result.concat(this.flattenTree(node.children, flatNode));
        }
        delete flatNode.children;
      }
      return result;
    },
    //树
    getdemotree () {
      project
        .getTree({ organizationId: this.dataForm.organizationId })
        .then((res) => {
          if (res.status == 200) {
            this.treeData = res.data.data;
            this.treeArrData = this.flattenTree(res.data.data);
          }
        });
    },
    typeTree () {
      API.getTreeTypes({
        actId: this.dataForm.id,
      }).then((res) => {
        this.corePersonnelTreeData = res.data.data;
        if (res.data.data.length != 0) {
          this.selectTree = res.data.data.length?res.data.data[0]:{}
          if (!this.selectTree.id) return;
          this.defaultExpandedKeys = [this.selectTree.id];
          this.$nextTick(() => {
            this.$refs.commonTreeCore.$refs.commonTree.setCurrentKey(
              this.selectTree.id
            );
            this.$refs.commonTreeCore.nodeData = this.selectTree;
            this.corePersonnelTreeNodeClick(this.selectTree);
          });
        }
      });
    },
    resetChange () {
      this.jcxflModel = false;
      this.jcxflForm = {};
    },
    resetChange1 () {
      this.jcxModel = false;
      this.jcxForm = {};
    },
    handleSubmit1 () {
      API.hdcheckindexSubmit({
        ...this.jcxForm,
        objectId: this.selectTree.id,
        objectName: this.selectTree.typeName,
        actId: this.dataForm.id,
      }).then((res) => {
        this.jcxModel = false;
        this.$message.success(res.data.msg);
        this.getJcx();
        this.jcxForm = {};
      });
    },
    handleSubmit (form, done) {
      let data = {
        ...this.jcxflForm,
        parentId: this.jcxflForm.parentId || -1,
        typeId: this.jcxflForm.parentId || -1,
        actId: this.dataForm.id,
      };
      let url = API.hdcheckindextype;
      // this.jcxflForm.isObj == 0 ? API.hdcheckindextype : API.hdcheckindexObj;
      url(data)
        .then((res) => {
          this.jcxflModel = false;
          this.$message.success(res.data.msg);
          this.typeTree();
        })
        .catch((err) => {
          done();
        });
    },
    getJcx (page) {
      this.page = page || this.page;
      this.jcxTableLoading = true;
      let urlFun =
        this.dataForm.actStatus == "FINISHED" && this.formType == "view"
          ? hdchecktasklineList
          : API.hdcheckindexList;
      let obj = {
        objectId: this.selectTree.id,
      };

      urlFun({
        current: this.page.currentPage,
        size: this.page.pageSize,
        actId: this.dataForm.id,
        ...obj,
      }).then((res) => {
        this.page.total = res.data.data.total;
        this.$refs.jcxGridLayOut.page.total = this.page.total;
        this.corePersonnelTableData = res.data.data.records;
        this.jcxTableLoading = false;
      });
    },
    async addjcx (row = {}) {
      if(!this.corePersonnelTreeData.length){
        return this.$message.success("请选择检查项分类或对象");
      }

      if(!Object.keys(this.checkePersonObj).length){
        return this.$message.success("请配置检查项组长");
      }
        
      this.jcxForm = {
        ...row,
        objectId: this.selectTree.id,
        objectName: this.selectTree.typeName,
      };
      if(!Object.keys(row).length){
        this.jcxForm.respUserName = this.checkePersonObj.userName
        this.jcxForm.respNickName = this.checkePersonObj.nickName||this.checkePersonObj.userNickName
        this.jcxForm.respUserAccount = this.checkePersonObj.userAccount
      }
      this.jcxModel = true;
      this.$forceUpdate();
    },
    //导入
    handleImport () {
      if (!this.dataForm.id) {
        return this.$message({
          message: "基础信息未保存,请先保存基础信息",
          type: "warning",
        });
      }

      // if (!this.selectTree?.isObj)
      //   return this.$message.success("请选择检查项对象");
      this.excelBox = true;
      this.excelOption.excelForm.excelFile = [];
    },
    headExport () {
     if (!this.dataForm.id){
         return this.$message.success("基础信息未保存，请先保存基础信息");
      }
      exportBlob(
        `/api/sinoma-hse-prj//hdcheckindex/export?typeId=${this.selectTree?this.selectTree.id:''}&actId=${this.dataForm.id}`
      ).then((res) => {
        downloadXls(res.data, this.selectTree.typeName);
      });
    },
    uploadAfter (res, done, loading, column) {
      this.excelBox = false;
      this.typeTree()
      this.excelOption.excelForm.excelFile = [];
      done();
    },
    handleTemplate () {
      exportBlob(
        `/api/sinoma-hse-prj/hdcheckindex/exportTemplate?actId=${this.dataForm.id}`
      ).then((res) => {
        downloadXls(res.data, "检查项导入模板" + ".xlsx");
      });
    },

    getTreeAdd (row) {
      if (!this.dataForm.id) {
        return this.$message({
          message: "基础信息未保存,请先保存基础信息",
          type: "warning",
        });
      }
      // if (row.isObj == 1)
      //   return this.$message.error("当前节点为检查对象节点，不可新增节点");
      this.jcxflForm = {
        parentId: row.id || -1,
        isObject: row.isObject || 0,
        objectType: "SD",
        parentName: row.typeName,
      };
      this.$set(this.jcxflForm, "isObject", row.isObject || 0);
      this.jcxflModel = true;
    },
    headDel () {
      this.rowDel(this.selectionList);
    },
    rowDel (data) {
      this.selectionList = data;
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t("cip.cmn.msg.warning.selectWarning"));
        return;
      }
      let formData = new FormData();
      this.$confirm(this.$t("cip.cmn.msg.warning.delWarning"), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      })
        .then(() => {
          formData.append("ids", this.ids);
          return API.hdcheckindexRemove(formData);
        })
        .then(() => {
          this.selectionList = [];
          this.$message.success(this.$t("cip.cmn.msg.success.operateSuccess"));
          this.getJcx();
        });
    },
    getTreeDelete (data) {
      let formData = new FormData();
      this.$confirm(this.$t("cip.cmn.msg.warning.delWarning"), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      })
        .then(() => {
          formData.append("ids", data.id);
          return API.TypeTreeRemove(formData);
          // return data.isObj == 0
          //   ? API.TypeTreeRemove(formData)
          //   : API.hdcheckindexObjRemove(formData);
        })
        .then(() => {
          this.$refs.commonTreeCore.nodeData = {};
          this.$message.success(this.$t("cip.cmn.msg.success.delSuccess"));
          this.typeTree();
        });
    },
    getTreeEdit (row) {
      this.jcxflForm = {
        ...row,
        typeName: row.typeName,
        parentName: row.typeName,
      };
      // console.log(this.jcxflForm);
      this.$set(this.jcxflForm, "isObject", row.isObject);
      this.jcxflModel = true;
    },
    async getFileList (list) {
      await API.changeOrPunish({ actId: this.dataForm.id }).then((res) => {
        if (list == "RectifyList") {
          if (res.data.data) {
            let RectifyList = res.data.data
              .filter((item) => item.assessmentUrl)
              .map((item) => {
                if (item.assessmentUrl) {
                  return JSON.parse(item.assessmentUrl);
                } else {
                  return [];
                }
              });
            this.RectifyList = RectifyList.flat();
          }
        } else {
          if (res.data.data) {
            let punishmentList = res.data.data
              .filter((item) => item.punishUrl)
              .map((item) => {
                if (item.punishUrl) {
                  return JSON.parse(item.punishUrl);
                } else {
                  return [];
                }
              });
            this.punishmentList = punishmentList.flat();
          }
        }
      });
    },
    async getJcUser (val) {
      console.log(66)
      if (!this.dataForm.id) {
        return this.$message({
          message: "新增状态下已全量展示所选人员",
          type: "warning",
        });
      }
      // console.log(val);
      this.jcryPage = val || this.jcryPage;
      if(this.activeName == 'checkJoinTab'){
        this.checkJoinDataLoading = true;
      }else{
        this.jcryTableLoading = true;
      }
      
      await API.hdcheckpersonList({
        actId: this.dataForm.id,
        current: this.jcryPage.currentPage,
        size: this.jcryPage.pageSize,
        personType: this.activeName == 'checkJoinTab'?'joiner':'leader'
      }).then((res) => {
        if(this.activeName == 'checkJoinTab'){
          this.checkJoinData = res.data.data.records;
          this.cyryPage.total = res.data.data.total;
          if (this.$refs.gridLayOut2) {
            this.$refs.gridLayOut2.page.total = res.data.data.total;
          }
          //重新渲染表格，解决勾选框错行问题
          this.$nextTick(() => {
            this.$refs.gridLayOut2.$refs.grid.$refs.table.doLayout();
          });
          this.checkJoinDataLoading = false;
        }else{
          this.thirdPartyOrganizationTableData = res.data.data.records;
          this.jcryPage.total = res.data.data.total;
          if (this.$refs.gridLayOut1) {
            this.$refs.gridLayOut1.page.total = res.data.data.total;
          }
          //重新渲染表格，解决勾选框错行问题
          this.$nextTick(() => {
            this.$refs.gridLayOut1.$refs.grid.$refs.table.doLayout();
          });
          this.jcryTableLoading = false;
        }
      });
    },
    handleClick () {
      this.selectionList = [];
      this.selectTree = undefined;
      
      if (this.activeName == "corePersonnel") {
        if (!this.dataForm.id) {
          return 
        }
        this.typeTree();
        API.hdcheckpersonList({
        actId: this.dataForm.id,
        current: 1,
        size: 9999,
        personType: 'leader'
      }).then((res) => {
        this.checkePersonObj = res.data.data.records.length?res.data.data.records[0]:{}
      })
      } else if (this.activeName == "thirdPartyOrganization"||this.activeName == "checkJoinTab") {
        if (!this.dataForm.id) {
          this.$nextTick(()=>{
            if(this.activeName=='thirdPartyOrganization'){
               this.$refs.gridLayOut1.$refs.grid.$refs.table.doLayout();
            }else{
              this.$refs.gridLayOut2.$refs.grid.$refs.table.doLayout();
            } 
          })
          return 
        }
        this.getJcUser();
      } else if (this.activeName == "thirdPartyPersonnel") {
        if (!this.dataForm.id) {
          return 
        }
        this.getFile();
      } else if (this.activeName == "taskList") {
        if (!this.dataForm.id) return;
        hdchecktaskproPage({
          actId: this.dataForm.id,
        }).then((res) => {
          this.hdchecktaskproData = res.data.data.records;
        });
      } else if (this.activeName == "Rectify") {
        if (!this.dataForm.id) return;
        this.getFileList("RectifyList");
      } else if (this.activeName == "punishment") {
        if (!this.dataForm.id) return;
        this.getFileList("punishmentList");
      }
    },
    selectData1 (row) {
      this.deptShow1 = false;
      this.$set(this.dataForm, "organizationId", row.id);
      this.$set(this.dataForm, "organizationName", row.title);
      this.$set(this.dataForm, "deptCategory", row.deptCategory);
      // console.log("进行输出人员-------------------", row);
    },
    getUser(data){
      console.log('选择的人',data)
      if (this.type == "startUserName") {
        this.$set(this.dataForm, "startUserId", data.userId);
        this.$set(this.dataForm, "startUserName", data.userName);
      } else if (this.type == "jcry") {
        // let newRow = data.map((item) => {
        //   let obj =
        //   {
        //     ...item,
        //     attr1: item.stPostName,
        //     userId: item.userId,
        //     userName: item.account,
        //     userNickName:item.userNickName || item.userName,
        //     id: "",
        //   };
        //   if (this.dataForm.id) {
        //     obj.actId = this.dataForm.id;
        //   }
        //   return obj;
        // });
        // if (!this.dataForm.id) {
          
        //   let tempArr = this.thirdPartyOrganizationTableData.concat(newRow);
        //   const uniqueArr = tempArr.reduce((acc, cur) => {
        //     const hasDuplicate = acc.some(item => item.userId === cur.userId);
        //     if (!hasDuplicate) {
        //       acc.push(cur);
        //     }
        //     return acc;
        //   }, []);
        //   this.thirdPartyOrganizationTableData = uniqueArr;
        //   console.log(this.thirdPartyOrganizationTableData)
        //   return;
        // }

         let newRow = []
        let obj = {
          ...data,
           attr1: data.stPostName,
           userId: data.userId,
           userName: data.account,
           userNickName:data.userNickName || data.userName,
           id: "",
           personType: 'leader',
        }
        if (this.dataForm.id) {
          obj.actId = this.dataForm.id;
        }
        newRow.push(obj)
        if (!this.dataForm.id) {
          this.thirdPartyOrganizationTableData = newRow;
          this.deptShow = false;
          this.MultipShow = false;
          return;
        }
        
        API.hdcheckpersonSubmit(newRow).then((res) => {
          this.$message.success(res.data.msg);
          this.handleClick();
        });

      } else if (this.type == "checkLeaderName") {
        if(this.dataForm.checkLeaderId){
         
           if(!this.dataForm.id){
            this.thirdPartyOrganizationTableData.splice(this.thirdPartyOrganizationTableData.findIndex(el=>el.userId==this.dataForm.checkLeaderId),1)
          }
          if(this.dataForm.id&&this.thirdPartyOrganizationTableData.findIndex(item=>item.userId==this.dataForm.checkLeaderId)>-1){
            let formData = new FormData();
            let ids = this.thirdPartyOrganizationTableData.find(item=>item.userId==this.dataForm.checkLeaderId).id
            formData.append("ids", ids);
            API.hdcheckpersonremove(formData).then(res=>{
              this.userList = [];
              this.getJcUser();
            })
          }
        }
        
       this.$set(this.dataForm, "checkLeaderId", data.userId);
       this.$set(this.dataForm, "checkLeaderName", data.userName);

        let newRow = []
        let obj = {
          ...data,
           attr1: data.stPostName,
           userId: data.userId,
           userName: data.account,
           userNickName:data.userNickName || data.userName,
           id: "",
        }
        if (this.dataForm.id) {
          obj.actId = this.dataForm.id;
        }
        newRow.push(obj)

        if (!this.dataForm.id) {
          let tempArr = this.thirdPartyOrganizationTableData.concat(newRow);
          const uniqueArr = tempArr.reduce((acc, cur) => {
            const hasDuplicate = acc.some(item => item.userId === cur.userId);
            if (!hasDuplicate) {
              acc.push(cur);
            }
            return acc;
          }, []);
          this.thirdPartyOrganizationTableData = uniqueArr;
          this.deptShow = false;
          this.MultipShow = false;
          return;
        }
        
        API.hdcheckpersonSubmit(newRow).then((res) => {
          this.$message.success(res.data.msg);
          this.handleClick();
        });
      } else if (this.type == "checkJoinName") {
        this.$set(
          this.dataForm,
          "checkJoinId",
          data.map((item) => item.userId).join(",")
        );
        this.$set(
          this.dataForm,
          "checkJoinName",
          data.map((item) => item.userName).join(",")
        );
      } else if (this.type=="cyry"){
         let newRow = data.map((item) => {
          let obj =
          {
            ...item,
            personType: 'joiner',
            attr1: item.stPostName,
            userId: item.userId,
            userName: item.account,
            userNickName:item.userNickName || item.userName,
            id: "",
          };
          if (this.dataForm.id) {
            obj.actId = this.dataForm.id;
          }
          return obj;
        });
        if (!this.dataForm.id) {
          
          let tempArr = this.checkJoinData.concat(newRow);
          const uniqueArr = tempArr.reduce((acc, cur) => {
            const hasDuplicate = acc.some(item => item.userId === cur.userId);
            if (!hasDuplicate) {
              acc.push(cur);
            }
            return acc;
          }, []);
          this.checkJoinData = uniqueArr;
          console.log(this.thirdPartyOrganizationTableData)
          return;
        }
        API.hdcheckpersonSubmit(newRow).then((res) => {
          this.$message.success(res.data.msg);
          this.handleClick();
        });
      }
      this.deptShow = false;
      this.MultipShow = false;
    },
    selectData (row) {
      if (this.type == "startUserName") {
        this.$set(this.dataForm, "startUserId", row.id);
        this.$set(this.dataForm, "startUserName", row.realName);
      } else if (this.type == "respUserName") {
        this.$set(this.jcxForm, "respUserName", row.account);
        this.$set(this.jcxForm, "respNickName", row.realName);
      } else if (this.type == "jcry") {
        let newRow = row.selectAllData.map((item) => {
          let obj =
          {
            ...item,
            deptId: row.treeDeptId,
            deptName: row.treeDeptName,
            userId: item.id,
            userName: item.account,
            userNickName:item.userNickName || item.realName,
            id: "",
          };
          if (this.dataForm.id) {
            obj.actId = this.dataForm.id;
          }
          return obj;
        });
        if (!this.dataForm.id) {
          
          let tempArr = this.thirdPartyOrganizationTableData.concat(newRow);
          const uniqueArr = tempArr.reduce((acc, cur) => {
            const hasDuplicate = acc.some(item => item.userId === cur.userId);
            if (!hasDuplicate) {
              acc.push(cur);
            }
            return acc;
          }, []);
          this.thirdPartyOrganizationTableData = uniqueArr;
          console.log(this.thirdPartyOrganizationTableData)
          return;
        }
        API.hdcheckpersonSubmit(newRow).then((res) => {
          this.$message.success(res.data.msg);
          this.handleClick();
        });
      }
      this.deptShow = false;
      this.MultipShow = false;
    },
    handleProjectOPen () {
      this.deptShow1 = true;
      //this.$refs.selectProjectDialog.init()
    },
    changeProject (data) {
      this.$set(this.dataForm, 'organizationName', data.prjAs)
      this.$set(this.dataForm, 'organizationId', data.id)
    },
    headAdd (type) {
      this.type = type;
      this.deptShow = true;
    },
    headAdd1 (type) {
      this.type = type;
      this.MultipShow = true;
      // if (this.dataForm.id) {
      //   this.type = type;
      //   this.MultipShow = true;
      // } else {
      //   this.$message({
      //     message: "基础信息未保存,请先保存基础信息",
      //     type: "warning",
      //   });
      // }
    },
    headRemove () {
      this.rowUserDel(this.userList,'cyry');
    },
    rowUserDel (data,delTab) {
      this.userList = data;
      if (this.userList.length === 0) {
        this.$message.warning(this.$t("cip.cmn.msg.warning.selectWarning"));
        return;
      }
      let formData = new FormData();
      this.$confirm(this.$t("cip.cmn.msg.warning.delWarning"), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      })
        .then(() => {
          if(!this.dataForm.id){
            data.forEach((item,index)=>{
              if(delTab=='jcry'){
                this.thirdPartyOrganizationTableData.splice(this.thirdPartyOrganizationTableData.findIndex(el=>el.userId==item.userId),1)
              }else{
                this.checkJoinData.splice(this.checkJoinData.findIndex(el=>el.userId==item.userId),1)
              }
            })
             this.$message.success(this.$t("cip.cmn.msg.success.operateSuccess"));
            return
          }
          formData.append("ids", this.userIds);
          return API.hdcheckpersonremove(formData).then(res=>{
          this.userList = [];
          this.$message.success(this.$t("cip.cmn.msg.success.operateSuccess"));
          this.getJcUser();
          });
        })
    },
    corePersonnelTreeNodeClick (row) {
      this.selectTree = row;
      this.getJcx();
    },
    headImport () {
      // if (!this.dataForm.id) {
      //   return this.$message({
      //     message: "基础信息未保存,请先保存基础信息",
      //     type: "warning",
      //   });
      // }
      // console.log(111);
      this.$refs["uploadRef"].$children[0].$refs.input.click();
    },
    //批量选择
    selectionChange (list) {
      this.selectionList = list;
    },
    selectionUserChange (list) {
      this.userList = list;
    },
    headCancel () {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    saveData (actStatus, boolean, Fun) {
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          
          let hdCheckObj = {};

          let url = this.formType == 'add' && this.equipmentInfo.eqTypeId ? API.hdcheckactSubmitEq: Fun
          let params = this.formType == 'add' && this.equipmentInfo.eqTypeId ? { ...this.equipmentInfo }: {}// 通过设备添加检查需传参数
          if(!this.thirdPartyOrganizationTableData.length){
              return this.$message({
                message: "请添加检查组组长数据",
                type: "warning",
              })
          }
          console.log(params)
          if (!this.dataForm.id) {
            
            let fileArr = this.xgzlFileList.map(item => {
              return {
                name: item.name,
                link: item.link,
                originalName: item.originalName,
              }
            })
            hdCheckObj = {
              hdCheckPersonList: this.thirdPartyOrganizationTableData,
              hdCheckMaterialList: fileArr,
              hdCheckPersonJoinList: this.checkJoinData
            }
          }
          this.$loading();
          url({
            ...this.dataForm,
            actStatus: actStatus,
            ...hdCheckObj,
            ...params
          })
            .then((res) => {
              if (res.data.code == 200) {
                this.$message({
                  message: !boolean ? "保存成功" : "提交成功",
                  type: "success",
                });
                if (boolean) {
                  this.$loading().close();
                  this.$router.$avueRouter.closeTag();
                  this.$router.back();
                  return;
                } else {
                  this.dataForm.id = res.data.data;
                  this.getPrjbasicinfoDetail();
                }

                this.$loading().close();
              }
            })
            .catch((err) => {
              this.$loading().close();
            });
        }
      });
    },
    // 保存
    async headSave (boolean, actStatus) {
      let that = this;
      if (actStatus == "FINISHED") {
        let taskBool = await API.queryTask({ id: this.dataForm.id }).then(
          (res) => res.data.data
        );
        if (taskBool) {
          if (window.confirm("当前有任务未执行完，点击确认自动执行")) {
            that.saveData(actStatus, boolean, API.update);
          }
        } else {
          that.saveData(actStatus, boolean, API.update);
        }
      } else {
        this.saveData(actStatus, boolean, API.submit);
      }
    },
    // 根据ID查表单详情
    async getPrjbasicinfoDetail () {
      await API.detail({
        id: this.dataForm.id,
      }).then((res) => {
        if (res.data.code == 200) {
          this.dataForm = res.data.data;
        }
      });
      this.handleClick()
      this.getJcUser();
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    // 删除上传文件
    handleRemove (file, fileList) {
      let formData = new FormData();
      formData.append("ids", file.id);
      API.hdcheckmaterialRemove(formData).then((res) => {
        this.$message.success(this.$t("cip.cmn.msg.success.operateSuccess"));
        this.getFile();
      });
    },
    addFile (list) {
      API.hdcheckmaterialSubmit(list).then((res) => {
        this.$message.success(res.data.msg);
        this.getFile();
      });
    },
    handlePreview1 (file) {
      snows.downloadFile1(file.link, file.name);
    },
    handlePreview (item) {
      this.$refs.fileView.showFile(item.link);
    },
    getFile () {
      API.hdcheckmaterialList({
        actId: this.dataForm.id,
      }).then((res) => {
        this.xgzlFileList = res.data.data;
      });
    },
    selectFile (data) {
      let file = data.map((item) => {
        let file = item.attachList[0] || {};
        let fileObj = {
          name: item.fileName,
          extension: item.extension || item.fileSuffixType,
          link: file.link || item.fileCover,
        };
        if (this.dataForm.id) {
          fileObj.actId = this.dataForm.id;
        }
        return fileObj;
      });
      if (!this.dataForm.id) {
        this.xgzlFileList = file;
        console.log(this.xgzlFileList, '1');
        this.showKnowledgeSearchDialog = false;
        return;
      }
      this.addFile(file);
      this.showKnowledgeSearchDialog = false;
    },
    // 新增上传文件
    handleSuccess (response, file, fileList) {
      let { name, link, originalName } = response.data;
      if (this.dataForm.id) {
        this.addFile([
          {
            actId: this.dataForm.id,
            name: originalName,
            link: link,
            originalName: name,
          },
        ]);
      } else {
        this.xgzlFileList.push({
          name: originalName,
          link: link,
          originalName: name,
        })
        console.log(this.xgzlFileList);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .common_tree_handle {
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.shuttleBackBox {
  ::v-deep .avue-crud .el-table {
    height: 400px !important;
    max-height: 400px !important;
  }
}

.formContentBox {
  padding: 12px;
  height: calc(100% - 76px);
}

.formMain {
  width: 100%;
  height: 100%;
}

.formTopic {
  width: 100%;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid rgb(187, 187, 187);
  font-weight: 600 !important;
  font-size: 18px;
}

.shuttleBackBox {
  width: 100%;
  height: calc(100% - 160px);

  .main-box {
    height: calc(100% - 56px);
    overflow: scroll;
  }

  .el-tabs {
    width: 100%;
  }

  .upload-demo {
    background-color: #ffffff;
    padding-left: 10px;
  }

  .table-box {
    height: 100%;
    overflow: scroll;
    width: calc(100% - 280px);
  }
}

.dangerBox ::v-deep .el-col {
  margin-bottom: 8px !important;
}

.dangerBox ::v-deep .avue-crud .el-table {
  height: 380px !important;
  max-height: 380px !important;
}

.shutleBack {
  width: 33%;
  background: #ffffff;
  border: 1px solid #ebeef5;
  border-radius: 4px;
}

.leftRightBtn {
  margin: 211px 12px;
}

.shutleTitle {
  width: 100%;
  background-color: #f5f7fa;
  height: 40px;
  line-height: 40px;
  text-indent: 12px;
  border-bottom: 1px solid #ebeef5;
  font-size: 16px;
}

.shutContent {
  padding: 6px;
  height: 400px;
  overflow: auto;
}

.deptBox {
  line-height: 40px;
  color: #333333;
  border-bottom: 1px solid #ebeef5;
  font-size: 13px;
  display: flex;
}

.unitBox {
  width: 100%;
  display: flex;
  background-color: #f5f7fa;
  border-bottom: 1px solid #ebeef5;
}

.unitTitle {
  height: 40px;
  line-height: 40px;
  padding: 0 12px;
  font-size: 16px;
  cursor: pointer;
  border-right: 1px solid #ebeef5;
}

.searchTopBox {
  padding: 14px 12px;
  width: calc(100% - 24px);
  display: flex;
  justify-content: space-between;
}

.leftSearch {
  width: 100%;
}

.searchBox {
  width: calc(15% - 5px) !important;
  margin-right: 5px;
}

.content {
  display: flex;
  align-items: center;

  .img-content {
    width: 188px;
    height: 100px;
    padding: 3px;
    position: relative;
    border: 1px solid #ccc;
    border-radius: 3px;
    margin-right: 12px;

    .delete-icon {
      position: absolute;
      top: -6px;
      right: -6px;
      font-size: 16px;
      color: red;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
